import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { RepairHistory } from "../../models/Repair/Repair";
import Loader from "react-loader-spinner";
import { getAppointmentDateFormattedComment } from "../../utils/formatting";
import { SurveyTypes } from "../../models/shared/Enum";
import { woChronologyTabState } from "../../ducks/redux/actions/userChoices";
import { useDispatch } from "react-redux";


interface WorkOrderChronologySummaryProps extends RouteComponentProps<any> {

}
const WorkOrderChronologySummaryComponent: React.FC<WorkOrderChronologySummaryProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const workOrderData = useSelector(
        (state: any) => state?.userChoicesReducer?.workOrderData
    );
    const [repairHistoryDetails, setRepairHistoryDetails] = React.useState<
        RepairHistory[]
    >([]);
    const dispatch = useDispatch();
    useEffect(() => {

        (async () => {

            try {
                setIsLoading(true);
                const allItems: any = [];
                const repairHistory: any = await api.getRepairHistories(
                    workOrderData.identifiers?.worksOrderIdentifier
                );
                Promise.all([repairHistory]).then((results) => {
                    if (results?.length > 0) {
                        //We removed sorting logic from UI p360.
                        //Sorting is implemented at api level for Work order v2 History Items and this is the prod story no
                        //https://dev.azure.com/PeabodyTrust/Digital%20Platform/_workitems/edit/6757
                        results.map((items: any) => {   
                            items.map((eachItem: any) => {
                                const emergencyStatus = workOrderData?.statuses?.isEmergency;
                                const appointmentDateComment = getAppointmentDateFormattedComment(eachItem);
                                const unappointedComment = emergencyStatus ? t("Unappointed_Comment_Emergency") : t("Unappointed_Comment");

                                const allItemsObj: any = {
                                    Unappointed: {
                                        statusTitle: workOrderData.job?.repairType === SurveyTypes.survey ? t("Survey.Survey_Created_Title"): t("Unappointed_Title"),
                                        comments: workOrderData.job?.repairType === SurveyTypes.survey ? t("Survey.Survey_Unappointed_Comment") : unappointedComment
                                    },
                                    APCREATE01: {
                                        statusTitle: t("APCREATE01_Title"),
                                        comments: `${t("APCREATE01_Comment")} ${appointmentDateComment}.`,
                                    },
                                    APCREATE02: {
                                        statusTitle: t("APCREATE02_Title"),
                                        comments: `${t("APCREATE02_Comment")} ${appointmentDateComment}.`,
                                    },
                                    APCREATE03: {
                                        statusTitle: t("APCREATE03_Title"),
                                        comments: `${t("APCREATE03_Comment")} ${appointmentDateComment}.`,
                                    },
                                    APCREATE04: {
                                        statusTitle: t("APCREATE04_Title"),
                                        comments: `${t("APCREATE04_Comment")} ${appointmentDateComment}.`,
                                    },
                                    APCREATE05: {
                                        statusTitle: t("APCREATE05_Title"),
                                        comments: `${t("APCREATE05_Comment")} ${appointmentDateComment}.`,
                                    },
                                    APCREATE06: {
                                        statusTitle: t("APCREATE06_Title"),
                                        comments: `${t("APCREATE06_Comment")} ${appointmentDateComment}.`,
                                    },
                                    APCREATE07: {
                                        statusTitle: t("APCREATE07_Title"),
                                        comments: `${t("APCREATE07_Comment")} ${appointmentDateComment}.`,
                                    },
                                    APCREATE08: {
                                        statusTitle: t("APCREATE08_Title"),
                                        comments: `${t("APCREATE08_Comment")} ${appointmentDateComment}.`,
                                    },
                                    APCREATE09: {
                                        statusTitle: t("APCREATE09_Title"),
                                        comments: `${t("APCREATE09_Comment")} ${appointmentDateComment}.`,
                                    },
                                    APCREATE10: {
                                        statusTitle: t("APCREATE10_Title"),
                                        comments: `${t("APCREATE10_Comment1")} ${appointmentDateComment}. ${t("APCREATE10_Comment2")}`,
                                    },
                                    
                                    AUPDATE01: {
                                        statusTitle: t("AUPDATE01_Title"),
                                        comments: `${t("AUPDATE01_Comment1")} ${appointmentDateComment}. `,
                                    },
                                    AUPDATE02: {
                                        statusTitle: t("AUPDATE02_Title"),
                                        comments: `${t("AUPDATE02_Comment1")} ${appointmentDateComment}. `,
                                    },
                                    AUPDATE03: {
                                        statusTitle: t("AUPDATE03_Title"),
                                        comments: `${t("AUPDATE03_Comment1")} ${appointmentDateComment}.`,
                                    },
                                    AUPDATE05: {
                                        statusTitle: t("AUPDATE05_Title"),
                                        comments: `${t("AUPDATE05_Comment1")} ${appointmentDateComment} ${t(
                                            "AUPDATE05_Comment2"
                                        )}`,
                                    },
                                    AUPDATE06: {
                                        statusTitle: t("AUPDATE06_Title"),
                                        comments: `${t("AUPDATE06_Comment1")} ${appointmentDateComment}. ${t(
                                            "AUPDATE06_Comment2"
                                        )}`,
                                    },
                                    AUPDATE07: {
                                        statusTitle: t("AUPDATE07_Title"),
                                        comments: `${t("AUPDATE07_Comment")} ${appointmentDateComment}. `,
                                    },
                                    NOACCESS: {
                                        statusTitle: t("NOACCESS_Title"),
                                        comments: t("NOACCESS_Comment"),
                                    },
                                    ENGOR01:{
                                        statusTitle: t("ENGOR01_Title"),
                                        comments: workOrderData.job?.repairType === SurveyTypes.survey ? t("Survey.Survey_InProgress_Comment") : t("ENGOR01_Comment"),
                                    },
                                    ENGOS01: {
                                        statusTitle: t("ENGOS01_Title"),
                                        comments: t("ENGOS01_Comment"),
                                    },
                                    ENGOS03: {
                                        statusTitle: t("ENGOS03_Title"),
                                        comments: workOrderData.job?.repairType === SurveyTypes.survey ? t("Survey.Survey_AwaitingAppointment_Comment") : t("ENGOS03_Comment"),               
                                    },
                                    NOACC01: {
                                        statusTitle: t("NOACC01_Title"),
                                        comments: t("NOACC01_Comment"),
                                    },
                                    ENGOS05: {
                                        statusTitle: t("ENGOS05_Title"),
                                        comments: t("ENGOS05_Comment"),
                                    },
                                    ENGOS06: {
                                        statusTitle: t("ENGOS06_Title"),
                                        comments: t("ENGOS06_Comment"),
                                    },
                                    ENGOS07: {
                                        statusTitle: t("ENGOS07_Title"),
                                        comments: t("ENGOS07_Comment"),
                                    },
                                    Completed: {
                                        statusTitle: workOrderData.job?.repairType === SurveyTypes.survey ? t("Survey.Survey_Completed_Title") : t("Completed_Title"),
                                        comments: workOrderData.job?.repairType === SurveyTypes.survey ? t("Survey.Survey_Completed_Comment") : t("Completed_Comment"),
                                 
                                    },
                                    Cancelled: {
                                        statusTitle: workOrderData.job?.repairType === SurveyTypes.survey ? t("Survey.Survey_Cancelled_Title") : t("Cancelled_Title"),
                                        comments: workOrderData.job?.repairType === SurveyTypes.survey ? t("Survey.Survey_Cancelled_Comment") : t("Cancelled_Comment"),
                                 
                                    },
                                    Variation: {
                                        statusTitle: t("Variation_Title"),
                                        comments: t("Variation_Comment"),
                                    }
                                };
                                let itemObject = {};
                                if (eachItem.subType && eachItem.subType.indexOf("Variation") > -1) {
                                    itemObject = {
                                        statusTitle:
                                            allItemsObj[eachItem.subType]?.statusTitle || "",
                                        comments: allItemsObj[eachItem.subType]?.comments || "",
                                        createDateTime: eachItem.createDateTime,
                                    };
                                }
                                else {
                                    itemObject = {
                                        statusTitle:
                                            allItemsObj[eachItem.identifier]?.statusTitle || "",
                                        comments: allItemsObj[eachItem.identifier]?.comments || "",
                                        createDateTime: eachItem.createDateTime,
                                    };
                                }
                                if (eachItem.subType !== "WorkOrder") {
                                    allItems.push(itemObject);
                                }

                            });                            
                        });

                        if (allItems.length > 0) {
                            const filteredItems = allItems.filter(
                                (item: any) => item.statusTitle !== ""
                            );
                            const filteredHistorySummary = filteredItems.slice(0, 2);
                            setRepairHistoryDetails(filteredHistorySummary);
                        }
                    }
                    setIsLoading(false);
                });
            } catch (e) {
                console.log(e);
                props.history.push("/genericerror");
            }

        })();
    }, []);

    const callWorkOrderChronologyPage = () => {
        dispatch(woChronologyTabState("History"));
        props.history.push("/WorkOrderChronology");
    }

    return (
        <div className="py-2">
            <div className="w-full">
                {isLoading ? (
                    <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={70}
                        width={50}
                        timeout={5000}
                    />
                ) : (
                  <>
                  <div className="border-b border-grey  py-2">
                    <div className="flex flex-row d-flex bd-highlight mb-2 items-center px-4">
                        <i className="fas fa-tools text-teal text-2xl mr-6 bd-highlight aa "></i>
                        <h2 className="my-0 text-purple-100 bd-highlight">{workOrderData.job?.repairType === SurveyTypes.survey ? t("Survey.Survey_History") : t("Repair_history")}</h2>           
                    </div>
                    </div>
                    <div className="px-4 py-2"></div>
                        {repairHistoryDetails?.length > 0 ? (
                            <>
                        {
                        repairHistoryDetails?.map(
                            (eachRepairUpdate: RepairHistory, key: any) => {
                                return (
                                <div className="px-4 py-2">
                                    <div className="border border-grey bg-white rounded-lg mb-2">
                                        <div className="bg-peach px-4 py-2 rounded-t-lg global-txt">
                                            <div className="flex flex-row justify-between items-center">
                                                <p className="my-0 text-lg"><strong>{eachRepairUpdate.statusTitle}</strong></p>
                                            </div>
                                        </div>

                                        <div className="px-4 grid grid-cols-1 mt-3 global-txt">
                                            <div className="mb-2">
                                                <strong>{t("date")}</strong><br />  {moment(eachRepairUpdate.createDateTime).format(
                                                    "DD-MM-YYYY, h:mm:ss A"
                                                )}
                                            </div>
                                            <div className="mb-2">
                                                <strong>{t("comment")}</strong><br />{eachRepairUpdate.comments}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    )
                    }
                   < div className="w-full px-4 py-4 flex justify-between absolute bottom-0 global-txt">
                      <div>
                        <a href="" data-testId="ViewAllWorkOrderChronology" className="text-teal no-underline hover:underline" onClick={callWorkOrderChronologyPage}>{t("ViewAll_WorkOrder_Chronology")} &gt;</a>
                      </div>
                   </div>
                   </>
                    ) : (
                      <div>
                        <p className="w-full text-left my-2 font-AvantGardeGothic-Md px-4 mt-4">
                            { t("No_WorkOrder_Chronology")}
                        </p>
                        </div>
                    )}
                        </>
                    )
                }
            </div>
        </div>

    )
}

export default WorkOrderChronologySummaryComponent;