import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import {
  formatContent,
  formatDateWithFromCalendar,
  isNullOrUndefined,
} from "../../utils/formatting";
import { WorksOrder } from "../../models/WorksOrders";
import { RepairChannel } from "../../models/Repair/RepairChannel";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import { trackEvent } from "../../appInsights/TelemetryService";
import { useDispatch, useSelector } from "react-redux";
import {
  getContractorName,
  getIntegeratedContractor,
  selectedTenacyType,
} from "../../ducks/redux/actions/getAddress";
import { RepairStatusField } from "../../models/Repair/Repair";
import { SurveyTypes } from "../../models/shared/Enum";
import { ContractorRepairPriority, RepairPriority } from "../../models/Repair/CreateRepair";
import { priorityCode } from "../../ducks/redux/actions/dashboardReducer.actions";

interface WorkOrderDetailsProps extends RouteComponentProps<any> {
  id: string; status: string;
}
const WorkOrderDetailsComponent: React.FC<WorkOrderDetailsProps> = (props) => {
  const { t } = useTranslation();
  const workOrderItem: WorksOrder = {};
  const [isLoading, setIsLoading] = useState(true);
  const [workorder, setWorkOrderData] = useState(workOrderItem);
  const [contractorName, setContractorName] = useState("");
  const [contractorEmail, setContractorEmail] = useState("");
  const [contractorPhone, setContractorPhone] = useState("");
  const [statusChangeDate, setStatusChangeDate] = useState("");
  const [isActiveDescription, setIsActiveDescription] = useState(false);
  const [activeWorkOrder, setActiveWorkOrder] = React.useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [dropDown, setDropDown] = useState("none");
  const [workOrderType, setWorkOrderType] = useState("");
  const dispatch = useDispatch();
  
  const workOrderData = useSelector(
    (state: any) => state?.userChoicesReducer?.workOrderData
  );
  const catalystStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.catalystStatus
  );
  const assetValue = useSelector(
    (state: any) => state?.userChoicesReducer?.assetValue
  );
  const isActiveTenancy = useSelector(
    (state: any) => state?.userChoicesReducer?.isActiveTenancy
  );
  const isP360Maintainence = useSelector(
    (state: any) => state.userChoicesReducer?.isP360RepairsMaintainenceValue
  );
  const contractorNotKnown = workOrderData.identifiers.jobNumber !== null ? t("Unknown"): "-";

  useEffect(() => {
    trackEvent("WorkOrderDetails");
    (async () => {
      getWorkOrder(props.id);
    })();
  }, [isActiveTenancy, workOrderData]);

  const dropDownFunction = () => {
    if (dropDown === "none") setDropDown("block");
    else setDropDown("none");
  };
  const callPageHandler = () => {
    if(!isP360Maintainence){
    dispatch(selectedTenacyType(workOrderData?.asset?.type?.assetTypeName));
    dispatch(priorityCode(''));
    props.history.push("/RepairAsset");
  };
}

  const getWorkOrder = (id: string) => {
    (async () => {
      try {
        if (workOrderData != null) {
          if (workOrderData.identifiers != null) {
            if (!isNullOrUndefined(workOrderData.identifiers.contractorSiteIdentifier)) {
              const contractorsData = await api.getContractors(
                workOrderData.identifiers.contractorSiteIdentifier
              );
              if (contractorsData?.length > 0) {
                setContractorEmail(contractorsData[0]?.contacts[0]?.addresses?.digitalAddresses[0]?.emailAddresses[0]?.value)
                if (contractorsData[0]?.contacts[0]?.addresses?.digitalAddresses[0]?.telecomsAddresses[0]?.number)
                  setContractorPhone(contractorsData[0]?.contacts[0]?.addresses?.digitalAddresses[0]?.telecomsAddresses[0]?.number);
                dispatch(
                  getIntegeratedContractor(
                    contractorsData[0]?.statuses.isIntegrated
                  )
                );
                dispatch(getContractorName(contractorsData[0]?.name));
                setContractorName(contractorsData[0]?.name);
              }
            }
            const repairHistories: any = await api.getRepairHistories(
              workOrderData.identifiers.worksOrderIdentifier
            );
            Promise.all([repairHistories]).then((results) => {
              if (results?.length > 0) {
                const sortedResults = results[0]?.sort(
                  (a: any, b: any) =>
                    new Date(b.createDateTime).valueOf() -
                    new Date(a.createDateTime).valueOf()
                )
                  .slice(0, 3);
                if (sortedResults?.length > 0) {
                  setStatusChangeDate(sortedResults[0].createDateTime);
                }
              }
            });
          }
          setWorkOrderData(workOrderData);
          setIsLoading(false);
        }
        if (workOrderData?.statuses.status === RepairStatusField.CatalystTier2Triage) {
          setWorkOrderType(RepairStatusField.Tier2);
        }
        else if (workOrderData?.statuses.status === RepairStatusField.CatalystEMATriage) {
          setWorkOrderType(RepairStatusField.EMAtriage);
        }
        else if (workOrderData?.statuses.status === RepairStatusField.CatalystDefectTriage) {
          setWorkOrderType(RepairStatusField.Defecttriage);
        }
      } catch (e) {
        setIsLoading(false);
        console.error(e);
        props.history.push("/GenericError");
      }
    })();
  };

  const retrievePriority = (
    isEmergency: boolean | undefined,
    isOutOfHours: boolean | undefined,
    isRecall: boolean | null | undefined
  ) => {
    let priority = "";
    if (isRecall) {
      priority = "Next Available (Recall)";
    } else if (workOrderData?.job?.priority?.code === ContractorRepairPriority.DampAndMould) {
      priority = RepairPriority.DM14Days;
    } else {
      priority = isEmergency
        ? isOutOfHours
          ? "Emergency (Out of hours)"
          : "Emergency"
        : "Next Available";
    }
    return priority;
  };

  const viewWorkOrderDescriptionHandler = (id: string, description: string) => {
    if (activeWorkOrder != "" && activeWorkOrder.indexOf(id) >= 0) {
      setActiveWorkOrder("");
      setAdditionalInfo("");
      setIsActiveDescription(false);
    } else {
      setActiveWorkOrder(id);
      if (workorder.job?.repairType === SurveyTypes.survey) {
        setAdditionalInfo(workorder.job?.additionalInformation || "");
      } else {
        if(workorder.channel != RepairChannel.CustomerSelfService &&
          workorder.channel != RepairChannel.ERGO &&
          workorder.channel != RepairChannel.ColleagueSelfServe) {
          // Description is not always concatenated
          setAdditionalInfo(workorder.job?.description ?? "-");
        } else if(isNullOrUndefined(workorder.job?.repairReason) && isNullOrUndefined(workorder.job?.additionalInformation)) {
          // Description is not always concatenated
          setAdditionalInfo('-');
        } else {
          // Description is not always concatenated
          setAdditionalInfo(workorder.job?.repairReason +'. '+ workorder.job?.additionalInformation);
        }
      }
      setIsActiveDescription(true);
    }
  };

  const viewActiveToggle = useCallback(
    (id: any) => {
      if (activeWorkOrder === id) {
        return (
          <div
            id={"WorkOrderAdditionalInfo-active" + id}
            data-testid="WorkOrderAdditionalInfo-active-multiply"
            className="plus-sign mt-10"
          >
            {"⨯"}
          </div>
        );
      } else {
        return (
          <div
            id={"WorkOrderAdditionalInfo-active" + id}
            data-testid="WorkOrderAdditionalInfo-active-plus"
            className="plus-sign mt-10"
          >
            {"+"}
          </div>
        );
      }
    },
    [activeWorkOrder, isActiveDescription]
  );

  const viewWorkOrderDetail = useCallback(
    (description: string) => {
      if (description !== undefined && description !== "") {
        return (
          <div
            className="accordion-content w-full global-txt"
            id="WorkOrderAdditionalInfo-accor2"
          >
            <div
              className=""
              x-ref="container"
              id="WorkOrderAdditionalInfo-con"
            >
              <div
                className="grid global-txt"
                id="WorkOrderAdditionalInfo-div1"
              >
                <div
                  className="box pl-6 ml-6 "
                  id="WorkOrderAdditionalInfo-div2"
                >
                  <div
                    className="flex flex-row flex-col "
                    id="WorkOrderAdditionalInfo-div3"
                  >
                    <div
                      className="pr-12 mt-2 mb-2 ml-4 wd-100 div-word-break"
                      id="WorkOrderAdditionalInfo-div4"
                    >
                      <div>{description}</div>
                    </div>
                  </div>
                  {props.status === RepairStatusField.CatalystTier2Triage || props.status === RepairStatusField.CatalystEMAStatus ||
                    props.status === RepairStatusField.CatalystDefectTriage ? (

                    <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
                      <div className="px-2 pr-8">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-purple-100 w-6 h-6">
                          <path d="M480 179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V448C480 460.9 472.2 472.6 460.2 477.6C448.3 482.5 434.5 479.8 425.4 470.6L381.7 426.1C333.7 378.1 268.6 352 200.7 352H192V480C192 497.7 177.7 512 160 512H96C78.33 512 64 497.7 64 480V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128H200.7C268.6 128 333.7 101 381.7 53.02L425.4 9.373C434.5 .2215 448.3-2.516 460.2 2.437C472.2 7.39 480 19.06 480 32V179.6zM200.7 192H192V288H200.7C280.5 288 357.2 317.8 416 371.3V108.7C357.2 162.2 280.5 192 200.7 192V192z"></path></svg>
                      </div>
                      <div className="flex items-center">
                        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                          {props.status === RepairStatusField.CatalystTier2Triage ? t("CatalystTIER2WOMsg") :
                            props.status === RepairStatusField.CatalystEMAStatus ? t("CatalystEMAWOMsg") :
                              props.status === RepairStatusField.CatalystDefectTriage ? t("CatalystDEFECTWOMsg") : null}

                        </p>
                      </div>
                    </div>
                  ) :
                    (null)
                  }

                </div>
              </div>
            </div>
          </div>
        );
      }
    },
    [additionalInfo]
  );

  return (
    <>
      {!isLoading ? (
        <div className="w-full desk-view-only" data-testid="work-order">
          <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
            <div className="bg-peach px-4 py-2 rounded-t-lg">
              <div className="flex flex-row justify-between items-center">
                <div className="my-0 text-lg">
                  <i className="fas fa-tools text-teal text-2xl mr-6 sm:mr-4 bd-highlight"></i>
                  <strong>
                    {t("Work_Order_Type_CP041")} {workorder.job?.repairType
                      ? (workorder.job.repairType[0].toUpperCase() + workorder.job.repairType.slice(1))
                      : (workorder.job?.workProgramme != null && workorder.job?.workProgramme !== "" && workorder.job?.workProgramme !== undefined)
                        ? t(workorder.job?.workProgramme)
                        : workorder.statuses?.isDraft ? t("draft") : workOrderType
                          ? workOrderType : null}
                  </strong>
                </div>
                {(assetValue === "Property" 
                ? isActiveTenancy 
                : true) ? (
                  <div className="menu-nav ms-auto bd-highlight aa">
                    <div className="dropdown-container btn-group">
                      <div
                        className="three-dots btn btn-sm show"
                        data-testid="dropFunction"
                        data-bs-toggle="dropdown"
                        onClick={dropDownFunction}
                      ></div>

                      <div
                        className="dropdown-ast px-2 dropdown-menu mt-45 show cc"
                        style={{ display: dropDown }}
                      >
                        <a
                          onClick={callPageHandler}
                          data-testid="callHandler"
                          className="text-black  text-base no-underline"
                        >
                          <div className={"cursor-pointer dropdown-item text-black " + (isP360Maintainence ? 'text-opacity-25 pe-none' : '')}>
                            {assetValue ===
                              "Property"
                              ? t("Create_repair")
                              : t("raise_comm")}
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3 desk-view">
              <div className="mb-2">
                <strong>{t("Date_Creation")}</strong>
                <br></br>
                {formatDateWithFromCalendar(workorder.raisedDate || "")}
              </div>

              <div className="mb-2 md:ml-4" data-testid="DMPriorityValue">
                <strong>{t("Priority_CP103")}</strong>
                <br></br>

                {formatContent(
                  retrievePriority(
                    workorder.statuses?.isEmergency,
                    workorder.statuses?.isOutOfHours,
                    workorder.statuses?.isRecall
                  )
                )}
              </div>
              <div className="mb-2" data-testid="createdBy">
                <strong>{t("Created_By_CP115")}</strong>
                <br></br>
                {formatContent(workorder.job?.createdBy && workorder.channel === 'ColleagueSelfService' ? workorder.job?.createdBy : workorder.channel)}
              </div>

              <div className="mb-2 md:ml-4">
                <strong>{t("Description_CP067")}</strong>
                <br></br>

                {workorder.job?.trade == "Unknown" &&
                  workorder.channel != RepairChannel.CustomerSelfService &&
                  workorder.channel != RepairChannel.ERGO &&
                  workorder.channel != RepairChannel.ColleagueSelfServe
                  ? t("RepairDescription_ErrorMessage")
                  : workorder.channel === RepairChannel.CustomerSelfService ||
                    workorder.channel === RepairChannel.ERGO ||
                    workorder.channel === RepairChannel.ColleagueSelfServe
                    ? workorder.statuses?.isRecall && workorder.identifiers?.jobNumber ? formatContent(workorder.job?.description?.split(".")[0]) + " . " + formatContent(workorder.job?.description?.split(".")[1]) : formatContent(workorder.job?.description?.split(".")[0])
                    : formatContent(workorder.job?.trade)}
              </div>

              <div className="mb-2">
                <strong>{t("Status_CP068")}</strong>
                <br></br>
                {(workorder.statuses?.status === "In Diagnosis" &&
                    (workorder.statuses?.isPotentialDuplicate === true ||
                      workorder?.customer?.vulnerability?.isVulnerable === true ||
                      workorder?.job?.sorCode === "N/A" || workorder?.job?.sorCode === "NA") && workorder.statuses.isSubmitted)
                    ? "Under review"
                :workorder.statuses?.status === "In Diagnosis"
                  ? t("draft")
                  : ((workorder.statuses?.status === "Unappointed" ||
                  workorder.statuses?.status === "Reported to EMA") && workorder.job?.repairType === SurveyTypes.survey) 
                  ? t('assigned_surveyor') 
                  : workorder.statuses?.status === "Unappointed" ||
                    workorder.statuses?.status === "Reported to EMA"
                  ? t("assigned_contractor")
                     : formatContent(catalystStatus)}
              </div>

              <div className="mb-2 md:ml-4">
                <strong>{t("Contractor_CP114")}</strong>
                <br></br>

                {contractorName && contractorName !== "" ? contractorName === "Peabody Surveying Team" ? t('peabody_surveyor') : contractorName : contractorNotKnown}
              </div>
              <div className="mb-2">
                <strong>{t("Status_Change_Date_CP044")}</strong>
                <br></br>
                {formatDateWithFromCalendar(statusChangeDate)}
              </div>
              <div className="mb-2 md:ml-4">
                <strong>{t("Contractor_phone_no")}</strong>
                <br></br>
                {contractorPhone && contractorPhone !== "" ? contractorPhone : contractorNotKnown}
              </div>


              {
                workorder.job?.repairType !== SurveyTypes.survey ?
                  <React.Fragment>
                    <div className="mb-2">
                      <strong>{t("Location_CP105")}</strong>
                      <br></br>
                      {formatContent(workorder.job?.location?.location)}
                    </div>
                    <div className="mb-2 md:ml-4" data-testid="ContractorEmail">
                      <strong>{t("Contractor_email")}</strong>
                      <br></br>
                      {contractorEmail && contractorEmail !== "" ? contractorEmail : contractorNotKnown}
                    </div>
                    <div className="mb-2" data-testid="assetType">
                      <strong>{t("property_type_txt")}</strong>
                      <br></br>
                      {formatContent(workorder.asset?.type?.assetTypeName)}
                    </div>
                  </React.Fragment>
                  : <React.Fragment>
                    <div className="mb-2" data-testid="assetType">
                      <strong>{t("property_type_txt")}</strong>
                      <br></br>
                      {formatContent(workorder.asset?.type?.assetTypeName)}
                    </div>
                    <div className="mb-2 md:ml-4" data-testid="ContractorEmail">
                      <strong>{t("Contractor_email")}</strong>
                      <br></br>
                      {contractorEmail && contractorEmail !== "" ? contractorEmail : contractorNotKnown}
                    </div>
                  </React.Fragment>

              }
            </div>

            <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3 mob-view">
              <div className="mb-2">
                <strong>{t("Date_Creation")}</strong>
                <br></br>
                {formatDateWithFromCalendar(workorder.raisedDate || "")}
              </div>
              <div className="mb-2">
                <strong>{t("Created_By_CP115")}
                </strong>
                <br></br>
                {formatContent(workorder.job?.createdBy && workorder.channel === 'ColleagueSelfService' ? workorder.job?.createdBy : workorder.channel)}
              </div>
              <div className="mb-2">
                <strong>{t("Status_CP068")}</strong>
                <br></br>
                {(workorder.statuses?.status === "In Diagnosis" &&
                  (workorder.statuses?.isPotentialDuplicate === true ||
                    workorder?.customer?.vulnerability?.isVulnerable === true ||
                    workorder?.job?.sorCode === "N/A" || workorder?.job?.sorCode === "NA") && workorder.statuses.isSubmitted)
                  ? "Under review"
                  : workorder.statuses?.status === "In Diagnosis"
                    ? t("draft")
                    : workorder.statuses?.status === "Unappointed" ||
                      workorder.statuses?.status === "Reported to EMA"
                      ? (workorder.job?.repairType === SurveyTypes.survey ? t("assigned_surveyor") : t("assigned_contractor"))
                      : formatContent(workorder.statuses?.status)}
              </div>
              <div className="mb-2">
                <strong>{t("Status_Change_Date_CP044")}</strong>
                <br></br>
                {formatDateWithFromCalendar(statusChangeDate)}
              </div>


              {
                workorder.job?.repairType !== SurveyTypes.survey &&
                <div className="mb-2">
                  <strong>{t("Location_CP105")}</strong>
                  <br></br>
                  {formatContent(workorder.job?.location?.location)}
                </div>
              }
              <div className="mb-2 ">
                <strong>{t("property_type_txt")}</strong>
                <br></br>
                {formatContent(workorder.asset?.type?.assetTypeName)}
              </div>
              <div className="mb-2 md:ml-4">
                <strong>{t("Priority_CP103")}</strong>
                <br></br>

                {formatContent(
                  retrievePriority(
                    workorder.statuses?.isEmergency,
                    workorder.statuses?.isOutOfHours,
                    workorder.statuses?.isRecall
                  )
                )}
              </div>
              <div className="mb-2 md:ml-4">
                <strong>{t("Description_CP067")}</strong>
                <br></br>

                {workorder.job?.trade == "Unknown" &&
                  workorder.channel != RepairChannel.CustomerSelfService &&
                  workorder.channel != RepairChannel.ERGO &&
                  workorder.channel != RepairChannel.ColleagueSelfServe
                  ? t("RepairDescription_ErrorMessage")
                  : workorder.channel === RepairChannel.CustomerSelfService ||
                    workorder.channel === RepairChannel.ERGO ||
                    workorder.channel === RepairChannel.ColleagueSelfServe
                    ? workorder.statuses?.isRecall && workorder.identifiers?.jobNumber ? formatContent(workorder.job?.description?.split(".")[0]) + " . " + formatContent(workorder.job?.description?.split(".")[1]) : formatContent(workorder.job?.description?.split(".")[0])
                    : formatContent(workorder.job?.trade)}

              </div>
              <div className="mb-2">
                <strong>{t("Contractor_CP114")}</strong>
                <br></br>

                {contractorName && contractorName !== "" ? contractorName : "-"}
              </div>
              <div className="mb-2" data-testid="ContractorPhone">
                <strong>{t("Contractor_phone_no")}</strong>
                <br></br>
                {contractorPhone && contractorPhone !== "" ? contractorPhone : "-"}
              </div>
              <div className="mb-2" data-testid="ContractorEmail">
                <strong>{t("Contractor_email")}</strong>
                <br></br>

                {contractorEmail && contractorEmail !== "" ? contractorEmail : "-"}
              </div>


            </div>
            {workorder.job &&
              workorder.job.description &&
              workorder.job.description !== "" && (
                <div
                  className="accordion-item"
                  id={
                    "WorkOrderAdditionalInfo-main-div" +
                    workorder.identifiers?.worksOrderIdentifier
                  }
                >
                  <div
                    className="accordion-title"
                    id={
                      "WorkOrderAdditionalInfo-main-accor1" +
                      workorder.identifiers?.worksOrderIdentifier
                    }
                  >
                    <div
                      className="flex items-center w-full px-3 py-2 cursor-pointer bg-blue-20 "
                      data-testid="WorkOrderAdditionalInfo-more-details"
                      id={
                        "WorkOrderAdditionalInfo-det" +
                        workorder.identifiers?.worksOrderIdentifier
                      }
                      onClick={(event) =>
                        viewWorkOrderDescriptionHandler(
                          workorder.identifiers?.worksOrderIdentifier ?? "",
                          workorder?.job?.description ?? ""
                        )
                      }
                    >
                      <p
                        className="w-full text-left my-2 global-txt mt-2"
                        id={
                          "WorkOrderAdditionalInfo-p1" +
                          workorder.identifiers?.worksOrderIdentifier
                        }
                      >
                        <strong>{t("More_DetailsCP106")}</strong>
                      </p>
                      {viewActiveToggle(
                        workorder.identifiers?.worksOrderIdentifier
                      )}
                    </div>
                    {viewWorkOrderDetail(additionalInfo)}
                  </div>
                </div>
              )}
          </div>
        </div>
      ) : (
        <div className="div-loader">
          <div className="SpinnerWrapper">
            <LoadingSpinner />
          </div>
        </div>
      )}
    </>
  );
};

export default WorkOrderDetailsComponent;
